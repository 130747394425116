var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "document-container",
      class: { locked: _vm.read_only },
      on: { click: () => _vm.setActiveBlockId(null) },
    },
    [
      _vm.requestError
        ? _c(
            "div",
            { staticClass: "error-message" },
            [
              _c(
                "el-alert",
                {
                  attrs: {
                    type: "error",
                    closable: false,
                    title: "Unable to edit document",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.requestError) + " ")]
              ),
            ],
            1
          )
        : [
            _c("div", { staticClass: "header" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("el-button", { on: { click: _vm.goBack } }, [
                    _vm._v("Close"),
                  ]),
                  _c("span", { staticClass: "document-label" }, [
                    _c("span", { staticClass: "doc-type" }, [
                      _vm._v(_vm._s(_vm.documentType)),
                    ]),
                    _vm.documentDisplayName
                      ? _c("span", [
                          _vm._v(
                            " Document for " +
                              _vm._s(_vm.documentDisplayName) +
                              " "
                          ),
                          _vm.document
                            ? _c("span", { staticClass: "document-name" }, [
                                _vm._v(" " + _vm._s(_vm.document.name) + " "),
                              ])
                            : _vm._e(),
                        ])
                      : _c("span", [_vm._v(" Template")]),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row" },
                [
                  !_vm.document
                    ? [
                        _c(
                          "el-select",
                          {
                            staticClass: "template-selector",
                            attrs: {
                              placeholder: "Select template",
                              "value-key": "id",
                            },
                            model: {
                              value: _vm.template,
                              callback: function ($$v) {
                                _vm.template = $$v
                              },
                              expression: "template",
                            },
                          },
                          _vm._l(_vm.templateList, function (t) {
                            return _c("el-option", {
                              key: t.id,
                              attrs: { label: t.name, value: t },
                            })
                          }),
                          1
                        ),
                        _vm.linkedRefId
                          ? _c(
                              "el-button",
                              {
                                attrs: { disabled: !_vm.template },
                                on: { click: _vm.handleCreateDocument },
                              },
                              [_vm._v(" Create document ")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: { disabled: !_vm.template },
                                on: { click: _vm.handleCreateTemplate },
                              },
                              [_vm._v(" Create template ")]
                            ),
                      ]
                    : [
                        !_vm.read_only
                          ? _c(
                              "el-dropdown",
                              {
                                staticStyle: { "margin-right": "10px" },
                                attrs: {
                                  trigger: "click",
                                  placement: "bottom",
                                },
                                on: { command: _vm.addBlock },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "dropdown",
                                      fn: function () {
                                        return [
                                          _c(
                                            "el-dropdown-menu",
                                            _vm._l(
                                              _vm.filteredDocumentBlocks,
                                              function (value, name) {
                                                return _c(
                                                  "el-tooltip",
                                                  {
                                                    key: name,
                                                    attrs: {
                                                      content:
                                                        value.description,
                                                      placement: "left",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-dropdown-item",
                                                      {
                                                        key: name,
                                                        staticClass:
                                                          "add-option",
                                                        attrs: {
                                                          command: name,
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(value.name)
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  4019371025
                                ),
                              },
                              [_c("el-button", [_vm._v(" Add ")])],
                              1
                            )
                          : _vm._e(),
                        !_vm.isTemplate
                          ? [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: "Preview PDF",
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    staticStyle: { "min-width": "50px" },
                                    attrs: {
                                      plain: "",
                                      icon: _vm.showPreview
                                        ? "el-icon-s-platform"
                                        : "el-icon-monitor",
                                    },
                                    on: {
                                      click: () =>
                                        (_vm.showPreview = !_vm.showPreview),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: "Download PDF",
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    staticStyle: {
                                      "min-width": "50px",
                                      "margin-right": "10px",
                                    },
                                    attrs: {
                                      plain: "",
                                      icon: "el-icon-download",
                                    },
                                    on: { click: _vm.downloadPDF },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        !_vm.read_only
                          ? _c(
                              "el-popconfirm",
                              {
                                attrs: {
                                  title: `Delete this ${
                                    _vm.isTemplate ? "template" : "document"
                                  }?`,
                                },
                                on: { confirm: _vm.deleteDocument },
                              },
                              [
                                _c("el-button", {
                                  staticStyle: { "min-width": "50px" },
                                  attrs: {
                                    slot: "reference",
                                    type: "danger",
                                    plain: "",
                                    icon: "el-icon-delete",
                                  },
                                  slot: "reference",
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                ],
                2
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "split-pane",
                class: { "show-preview": _vm.showPreview },
              },
              [
                _c(
                  "div",
                  { staticClass: "document" },
                  [
                    _vm.document
                      ? [
                          !_vm.isValid
                            ? _c("el-alert", {
                                attrs: {
                                  type: "warning",
                                  closable: false,
                                  title: "This document is incomplete.",
                                  description:
                                    "Please fill in the highlighted fields.",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "el-row",
                            { staticClass: "title-row", attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                [
                                  _c(
                                    "el-form",
                                    {
                                      ref: "docForm",
                                      attrs: {
                                        model: _vm.document,
                                        rules: _vm.rules,
                                      },
                                      on: { validate: _vm.handleFormValidated },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "name",
                                            label: _vm.isTemplate
                                              ? "Template name"
                                              : "Document name",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "document-name-input",
                                            attrs: {
                                              name: "name",
                                              type: "text",
                                              "auto-complete": "off",
                                              placeholder: "Template name",
                                              valid: false,
                                              readonly: _vm.read_only,
                                            },
                                            model: {
                                              value: _vm.document.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.document,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "document.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "draggable",
                            _vm._b(
                              {
                                staticClass: "content-blocks",
                                attrs: {
                                  disabled: _vm.read_only,
                                  list: _vm.sortedBlocks,
                                  handle: ".grip",
                                },
                                on: { end: _vm.updateBlockOrder },
                              },
                              "draggable",
                              _vm.dragOptions,
                              false
                            ),
                            [
                              _c(
                                "transition-group",
                                _vm._l(_vm.sortedBlocks, function (block) {
                                  return _c(
                                    "div",
                                    {
                                      key: block.id,
                                      ref: block.id,
                                      refInFor: true,
                                      staticClass: "block-wrapper",
                                      class:
                                        _vm.selectedBlockId === block.id
                                          ? "selected-block"
                                          : "",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return (() =>
                                            _vm.setActiveBlockId(
                                              block.id
                                            )).apply(null, arguments)
                                        },
                                      },
                                    },
                                    [
                                      _vm.DOCUMENT_BLOCKS[block.type]?.component
                                        ? _c(
                                            _vm.DOCUMENT_BLOCKS[block.type]
                                              .component,
                                            {
                                              tag: "component",
                                              attrs: {
                                                block: block,
                                                is_template: _vm.isTemplate,
                                                read_only: _vm.read_only,
                                                document: _vm.document,
                                                estimate: _vm.linkedRecord,
                                                project: _vm.linkedRecord,
                                              },
                                              on: {
                                                delete: _vm.handleDeleteBlock,
                                                update: _vm.handleUpdateBlock,
                                              },
                                            }
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm.showPreview && _vm.pdf_data
                  ? _c("object", {
                      staticClass: "pdf-preview",
                      attrs: {
                        data: `${_vm.pdf_url}#toolbar=0&navpanes=0`,
                        type: "application/pdf",
                      },
                    })
                  : _vm._e(),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }